import JsBarcode from 'jsbarcode'
import QRCode from 'qrcode'

export function handle128(element, data, options = {}) {
  JsBarcode(element, data, {
    format: 'CODE128B',
    displayValue: true,
    lineColor: 'black',
    ...options
  })
}

export function textToBase64Barcode(text) {
  const canvas = document.createElement('canvas')
  handle128(canvas, text)
  return canvas.toDataURL('image/png')
}

export async function handleQR(data, { margin = 4 } = {}) {
  return await QRCode.toDataURL(data, { margin })
}
